var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Base } from "../ContainerBase";
var Stack = /** @class */ (function (_super) {
    __extends(Stack, _super);
    function Stack(container) {
        if (container === void 0) { container = []; }
        var _this = _super.call(this) || this;
        /**
         * @internal
         */
        _this._stack = [];
        var self = _this;
        container.forEach(function (el) {
            self.push(el);
        });
        return _this;
    }
    Stack.prototype.clear = function () {
        this._length = 0;
        this._stack = [];
    };
    /**
     * @description Insert element to stack's end.
     * @description The element you want to push to the back.
     * @returns The container length after erasing.
     */
    Stack.prototype.push = function (element) {
        this._stack.push(element);
        this._length += 1;
        return this._length;
    };
    /**
     * @description Removes the end element.
     * @returns The element you popped.
     */
    Stack.prototype.pop = function () {
        if (this._length === 0)
            return;
        this._length -= 1;
        return this._stack.pop();
    };
    /**
     * @description Accesses the end element.
     * @returns The last element.
     */
    Stack.prototype.top = function () {
        return this._stack[this._length - 1];
    };
    return Stack;
}(Base));
export default Stack;
