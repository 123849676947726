var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import TreeContainer from './Base';
import TreeIterator from './Base/TreeIterator';
import { throwIteratorAccessError } from "../../utils/throwError";
var OrderedMapIterator = /** @class */ (function (_super) {
    __extends(OrderedMapIterator, _super);
    function OrderedMapIterator(node, header, container, iteratorType) {
        var _this = _super.call(this, node, header, iteratorType) || this;
        _this.container = container;
        return _this;
    }
    Object.defineProperty(OrderedMapIterator.prototype, "pointer", {
        get: function () {
            if (this._node === this._header) {
                throwIteratorAccessError();
            }
            var self = this;
            return new Proxy([], {
                get: function (_, props) {
                    if (props === '0')
                        return self._node._key;
                    else if (props === '1')
                        return self._node._value;
                },
                set: function (_, props, newValue) {
                    if (props !== '1') {
                        throw new TypeError('props must be 1');
                    }
                    self._node._value = newValue;
                    return true;
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    OrderedMapIterator.prototype.copy = function () {
        return new OrderedMapIterator(this._node, this._header, this.container, this.iteratorType);
    };
    return OrderedMapIterator;
}(TreeIterator));
var OrderedMap = /** @class */ (function (_super) {
    __extends(OrderedMap, _super);
    /**
     * @param container - The initialization container.
     * @param cmp - The compare function.
     * @param enableIndex - Whether to enable iterator indexing function.
     * @example
     * new OrderedMap();
     * new OrderedMap([[0, 1], [2, 1]]);
     * new OrderedMap([[0, 1], [2, 1]], (x, y) => x - y);
     * new OrderedMap([[0, 1], [2, 1]], (x, y) => x - y, true);
     */
    function OrderedMap(container, cmp, enableIndex) {
        if (container === void 0) { container = []; }
        var _this = _super.call(this, cmp, enableIndex) || this;
        var self = _this;
        container.forEach(function (el) {
            self.setElement(el[0], el[1]);
        });
        return _this;
    }
    /**
     * @internal
     */
    OrderedMap.prototype._iterationFunc = function (curNode) {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (curNode === undefined)
                        return [2 /*return*/];
                    return [5 /*yield**/, __values(this._iterationFunc(curNode._left))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, [curNode._key, curNode._value]];
                case 2:
                    _a.sent();
                    return [5 /*yield**/, __values(this._iterationFunc(curNode._right))];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
    OrderedMap.prototype.begin = function () {
        return new OrderedMapIterator(this._header._left || this._header, this._header, this);
    };
    OrderedMap.prototype.end = function () {
        return new OrderedMapIterator(this._header, this._header, this);
    };
    OrderedMap.prototype.rBegin = function () {
        return new OrderedMapIterator(this._header._right || this._header, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    OrderedMap.prototype.rEnd = function () {
        return new OrderedMapIterator(this._header, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    OrderedMap.prototype.front = function () {
        if (this._length === 0)
            return;
        var minNode = this._header._left;
        return [minNode._key, minNode._value];
    };
    OrderedMap.prototype.back = function () {
        if (this._length === 0)
            return;
        var maxNode = this._header._right;
        return [maxNode._key, maxNode._value];
    };
    OrderedMap.prototype.lowerBound = function (key) {
        var resNode = this._lowerBound(this._root, key);
        return new OrderedMapIterator(resNode, this._header, this);
    };
    OrderedMap.prototype.upperBound = function (key) {
        var resNode = this._upperBound(this._root, key);
        return new OrderedMapIterator(resNode, this._header, this);
    };
    OrderedMap.prototype.reverseLowerBound = function (key) {
        var resNode = this._reverseLowerBound(this._root, key);
        return new OrderedMapIterator(resNode, this._header, this);
    };
    OrderedMap.prototype.reverseUpperBound = function (key) {
        var resNode = this._reverseUpperBound(this._root, key);
        return new OrderedMapIterator(resNode, this._header, this);
    };
    /**
     * @description Insert a key-value pair or set value by the given key.
     * @param key - The key want to insert.
     * @param value - The value want to set.
     * @param hint - You can give an iterator hint to improve insertion efficiency.
     * @return The size of container after setting.
     * @example
     * const mp = new OrderedMap([[2, 0], [4, 0], [5, 0]]);
     * const iter = mp.begin();
     * mp.setElement(1, 0);
     * mp.setElement(3, 0, iter);  // give a hint will be faster.
     */
    OrderedMap.prototype.setElement = function (key, value, hint) {
        return this._set(key, value, hint);
    };
    OrderedMap.prototype.find = function (key) {
        var curNode = this._findElementNode(this._root, key);
        return new OrderedMapIterator(curNode, this._header, this);
    };
    /**
     * @description Get the value of the element of the specified key.
     * @param key - The specified key you want to get.
     * @example
     * const val = container.getElementByKey(1);
     */
    OrderedMap.prototype.getElementByKey = function (key) {
        var curNode = this._findElementNode(this._root, key);
        return curNode._value;
    };
    OrderedMap.prototype.union = function (other) {
        var self = this;
        other.forEach(function (el) {
            self.setElement(el[0], el[1]);
        });
        return this._length;
    };
    OrderedMap.prototype[Symbol.iterator] = function () {
        return this._iterationFunc(this._root);
    };
    return OrderedMap;
}(TreeContainer));
export default OrderedMap;
