var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Container, ContainerIterator } from "../../ContainerBase";
import checkObject from "../../../utils/checkObject";
import $checkWithinAccessParams from "../../../utils/checkParams.macro";
import { throwIteratorAccessError } from "../../../utils/throwError";
var HashContainerIterator = /** @class */ (function (_super) {
    __extends(HashContainerIterator, _super);
    /**
     * @internal
     */
    function HashContainerIterator(node, header, iteratorType) {
        var _this = _super.call(this, iteratorType) || this;
        _this._node = node;
        _this._header = header;
        if (_this.iteratorType === 0 /* IteratorType.NORMAL */) {
            _this.pre = function () {
                if (this._node._pre === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._pre;
                return this;
            };
            _this.next = function () {
                if (this._node === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._next;
                return this;
            };
        }
        else {
            _this.pre = function () {
                if (this._node._next === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._next;
                return this;
            };
            _this.next = function () {
                if (this._node === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._pre;
                return this;
            };
        }
        return _this;
    }
    return HashContainerIterator;
}(ContainerIterator));
export { HashContainerIterator };
var HashContainer = /** @class */ (function (_super) {
    __extends(HashContainer, _super);
    /**
     * @internal
     */
    function HashContainer() {
        var _this = _super.call(this) || this;
        /**
         * @internal
         */
        _this._objMap = [];
        /**
         * @internal
         */
        _this._originMap = {};
        /**
         * @description Unique symbol used to tag object.
         */
        _this.HASH_TAG = Symbol('@@HASH_TAG');
        Object.setPrototypeOf(_this._originMap, null);
        _this._header = {};
        _this._header._pre = _this._header._next = _this._head = _this._tail = _this._header;
        return _this;
    }
    /**
     * @internal
     */
    HashContainer.prototype._eraseNode = function (node) {
        var _pre = node._pre, _next = node._next;
        _pre._next = _next;
        _next._pre = _pre;
        if (node === this._head) {
            this._head = _next;
        }
        if (node === this._tail) {
            this._tail = _pre;
        }
        this._length -= 1;
    };
    /**
     * @internal
     */
    HashContainer.prototype._set = function (key, value, isObject) {
        if (isObject === undefined)
            isObject = checkObject(key);
        var newTail;
        if (isObject) {
            var index = key[this.HASH_TAG];
            if (index !== undefined) {
                this._objMap[index]._value = value;
                return this._length;
            }
            Object.defineProperty(key, this.HASH_TAG, {
                value: this._objMap.length,
                configurable: true
            });
            newTail = {
                _key: key,
                _value: value,
                _pre: this._tail,
                _next: this._header
            };
            this._objMap.push(newTail);
        }
        else {
            var node = this._originMap[key];
            if (node) {
                node._value = value;
                return this._length;
            }
            newTail = {
                _key: key,
                _value: value,
                _pre: this._tail,
                _next: this._header
            };
            this._originMap[key] = newTail;
        }
        if (this._length === 0) {
            this._head = newTail;
            this._header._next = newTail;
        }
        else {
            this._tail._next = newTail;
        }
        this._tail = newTail;
        this._header._pre = newTail;
        return ++this._length;
    };
    /**
     * @internal
     */
    HashContainer.prototype._findElementNode = function (key, isObject) {
        if (isObject === undefined)
            isObject = checkObject(key);
        if (isObject) {
            var index = key[this.HASH_TAG];
            if (index === undefined)
                return this._header;
            return this._objMap[index];
        }
        else {
            return this._originMap[key] || this._header;
        }
    };
    HashContainer.prototype.clear = function () {
        var HASH_TAG = this.HASH_TAG;
        this._objMap.forEach(function (el) {
            delete el._key[HASH_TAG];
        });
        this._objMap = [];
        this._originMap = {};
        Object.setPrototypeOf(this._originMap, null);
        this._length = 0;
        this._head = this._tail = this._header._pre = this._header._next = this._header;
    };
    /**
     * @description Remove the element of the specified key.
     * @param key - The key you want to remove.
     * @param isObject - Tell us if the type of inserted key is `object` to improve efficiency.<br/>
     *                   If a `undefined` value is passed in, the type will be automatically judged.
     * @returns Whether erase successfully.
     */
    HashContainer.prototype.eraseElementByKey = function (key, isObject) {
        var node;
        if (isObject === undefined)
            isObject = checkObject(key);
        if (isObject) {
            var index = key[this.HASH_TAG];
            if (index === undefined)
                return false;
            delete key[this.HASH_TAG];
            node = this._objMap[index];
            delete this._objMap[index];
        }
        else {
            node = this._originMap[key];
            if (node === undefined)
                return false;
            delete this._originMap[key];
        }
        this._eraseNode(node);
        return true;
    };
    HashContainer.prototype.eraseElementByIterator = function (iter) {
        var node = iter._node;
        if (node === this._header) {
            throwIteratorAccessError();
        }
        this._eraseNode(node);
        return iter.next();
    };
    HashContainer.prototype.eraseElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var node = this._head;
        while (pos--) {
            node = node._next;
        }
        this._eraseNode(node);
        return this._length;
    };
    return HashContainer;
}(Container));
export { HashContainer };
