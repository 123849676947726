var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HashContainer, HashContainerIterator } from "./Base";
import checkObject from "../../utils/checkObject";
import $checkWithinAccessParams from "../../utils/checkParams.macro";
import { throwIteratorAccessError } from "../../utils/throwError";
var HashMapIterator = /** @class */ (function (_super) {
    __extends(HashMapIterator, _super);
    function HashMapIterator(node, header, container, iteratorType) {
        var _this = _super.call(this, node, header, iteratorType) || this;
        _this.container = container;
        return _this;
    }
    Object.defineProperty(HashMapIterator.prototype, "pointer", {
        get: function () {
            if (this._node === this._header) {
                throwIteratorAccessError();
            }
            var self = this;
            return new Proxy([], {
                get: function (_, props) {
                    if (props === '0')
                        return self._node._key;
                    else if (props === '1')
                        return self._node._value;
                },
                set: function (_, props, newValue) {
                    if (props !== '1') {
                        throw new TypeError('props must be 1');
                    }
                    self._node._value = newValue;
                    return true;
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    HashMapIterator.prototype.copy = function () {
        return new HashMapIterator(this._node, this._header, this.container, this.iteratorType);
    };
    return HashMapIterator;
}(HashContainerIterator));
var HashMap = /** @class */ (function (_super) {
    __extends(HashMap, _super);
    function HashMap(container) {
        if (container === void 0) { container = []; }
        var _this = _super.call(this) || this;
        var self = _this;
        container.forEach(function (el) {
            self.setElement(el[0], el[1]);
        });
        return _this;
    }
    HashMap.prototype.begin = function () {
        return new HashMapIterator(this._head, this._header, this);
    };
    HashMap.prototype.end = function () {
        return new HashMapIterator(this._header, this._header, this);
    };
    HashMap.prototype.rBegin = function () {
        return new HashMapIterator(this._tail, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    HashMap.prototype.rEnd = function () {
        return new HashMapIterator(this._header, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    HashMap.prototype.front = function () {
        if (this._length === 0)
            return;
        return [this._head._key, this._head._value];
    };
    HashMap.prototype.back = function () {
        if (this._length === 0)
            return;
        return [this._tail._key, this._tail._value];
    };
    /**
     * @description Insert a key-value pair or set value by the given key.
     * @param key - The key want to insert.
     * @param value - The value want to set.
     * @param isObject - Tell us if the type of inserted key is `object` to improve efficiency.<br/>
     *                   If a `undefined` value is passed in, the type will be automatically judged.
     * @returns The size of container after setting.
     */
    HashMap.prototype.setElement = function (key, value, isObject) {
        return this._set(key, value, isObject);
    };
    /**
     * @description Get the value of the element of the specified key.
     * @param key - The key want to search.
     * @param isObject - Tell us if the type of inserted key is `object` to improve efficiency.<br/>
     *                   If a `undefined` value is passed in, the type will be automatically judged.
     * @example
     * const val = container.getElementByKey(1);
     */
    HashMap.prototype.getElementByKey = function (key, isObject) {
        if (isObject === undefined)
            isObject = checkObject(key);
        if (isObject) {
            var index = key[this.HASH_TAG];
            return index !== undefined ? this._objMap[index]._value : undefined;
        }
        var node = this._originMap[key];
        return node ? node._value : undefined;
    };
    HashMap.prototype.getElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var node = this._head;
        while (pos--) {
            node = node._next;
        }
        return [node._key, node._value];
    };
    /**
     * @description Check key if exist in container.
     * @param key - The element you want to search.
     * @param isObject - Tell us if the type of inserted key is `object` to improve efficiency.<br/>
     *                   If a `undefined` value is passed in, the type will be automatically judged.
     * @returns An iterator pointing to the element if found, or super end if not found.
     */
    HashMap.prototype.find = function (key, isObject) {
        var node = this._findElementNode(key, isObject);
        return new HashMapIterator(node, this._header, this);
    };
    HashMap.prototype.forEach = function (callback) {
        var index = 0;
        var node = this._head;
        while (node !== this._header) {
            callback([node._key, node._value], index++, this);
            node = node._next;
        }
    };
    HashMap.prototype[Symbol.iterator] = function () {
        return function () {
            var node;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        node = this._head;
                        _a.label = 1;
                    case 1:
                        if (!(node !== this._header)) return [3 /*break*/, 3];
                        return [4 /*yield*/, [node._key, node._value]];
                    case 2:
                        _a.sent();
                        node = node._next;
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/];
                }
            });
        }.bind(this)();
    };
    return HashMap;
}(HashContainer));
export default HashMap;
