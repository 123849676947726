var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import SequentialContainer from './Base';
import { ContainerIterator } from "../ContainerBase";
import $checkWithinAccessParams from "../../utils/checkParams.macro";
import { throwIteratorAccessError } from "../../utils/throwError";
var LinkListIterator = /** @class */ (function (_super) {
    __extends(LinkListIterator, _super);
    /**
     * @internal
     */
    function LinkListIterator(_node, _header, container, iteratorType) {
        var _this = _super.call(this, iteratorType) || this;
        _this._node = _node;
        _this._header = _header;
        _this.container = container;
        if (_this.iteratorType === 0 /* IteratorType.NORMAL */) {
            _this.pre = function () {
                if (this._node._pre === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._pre;
                return this;
            };
            _this.next = function () {
                if (this._node === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._next;
                return this;
            };
        }
        else {
            _this.pre = function () {
                if (this._node._next === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._next;
                return this;
            };
            _this.next = function () {
                if (this._node === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._pre;
                return this;
            };
        }
        return _this;
    }
    Object.defineProperty(LinkListIterator.prototype, "pointer", {
        get: function () {
            if (this._node === this._header) {
                throwIteratorAccessError();
            }
            return this._node._value;
        },
        set: function (newValue) {
            if (this._node === this._header) {
                throwIteratorAccessError();
            }
            this._node._value = newValue;
        },
        enumerable: false,
        configurable: true
    });
    LinkListIterator.prototype.copy = function () {
        return new LinkListIterator(this._node, this._header, this.container, this.iteratorType);
    };
    return LinkListIterator;
}(ContainerIterator));
var LinkList = /** @class */ (function (_super) {
    __extends(LinkList, _super);
    function LinkList(container) {
        if (container === void 0) { container = []; }
        var _this = _super.call(this) || this;
        _this._header = {};
        _this._head = _this._tail = _this._header._pre = _this._header._next = _this._header;
        var self = _this;
        container.forEach(function (el) {
            self.pushBack(el);
        });
        return _this;
    }
    /**
     * @internal
     */
    LinkList.prototype._eraseNode = function (node) {
        var _pre = node._pre, _next = node._next;
        _pre._next = _next;
        _next._pre = _pre;
        if (node === this._head) {
            this._head = _next;
        }
        if (node === this._tail) {
            this._tail = _pre;
        }
        this._length -= 1;
    };
    /**
     * @internal
     */
    LinkList.prototype._insertNode = function (value, pre) {
        var next = pre._next;
        var node = {
            _value: value,
            _pre: pre,
            _next: next
        };
        pre._next = node;
        next._pre = node;
        if (pre === this._header) {
            this._head = node;
        }
        if (next === this._header) {
            this._tail = node;
        }
        this._length += 1;
    };
    LinkList.prototype.clear = function () {
        this._length = 0;
        this._head = this._tail = this._header._pre = this._header._next = this._header;
    };
    LinkList.prototype.begin = function () {
        return new LinkListIterator(this._head, this._header, this);
    };
    LinkList.prototype.end = function () {
        return new LinkListIterator(this._header, this._header, this);
    };
    LinkList.prototype.rBegin = function () {
        return new LinkListIterator(this._tail, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    LinkList.prototype.rEnd = function () {
        return new LinkListIterator(this._header, this._header, this, 1 /* IteratorType.REVERSE */);
    };
    LinkList.prototype.front = function () {
        return this._head._value;
    };
    LinkList.prototype.back = function () {
        return this._tail._value;
    };
    LinkList.prototype.getElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var curNode = this._head;
        while (pos--) {
            curNode = curNode._next;
        }
        return curNode._value;
    };
    LinkList.prototype.eraseElementByPos = function (pos) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var curNode = this._head;
        while (pos--) {
            curNode = curNode._next;
        }
        this._eraseNode(curNode);
        return this._length;
    };
    LinkList.prototype.eraseElementByValue = function (_value) {
        var curNode = this._head;
        while (curNode !== this._header) {
            if (curNode._value === _value) {
                this._eraseNode(curNode);
            }
            curNode = curNode._next;
        }
        return this._length;
    };
    LinkList.prototype.eraseElementByIterator = function (iter) {
        var node = iter._node;
        if (node === this._header) {
            throwIteratorAccessError();
        }
        iter = iter.next();
        this._eraseNode(node);
        return iter;
    };
    LinkList.prototype.pushBack = function (element) {
        this._insertNode(element, this._tail);
        return this._length;
    };
    LinkList.prototype.popBack = function () {
        if (this._length === 0)
            return;
        var value = this._tail._value;
        this._eraseNode(this._tail);
        return value;
    };
    /**
     * @description Push an element to the front.
     * @param element - The element you want to push.
     * @returns The size of queue after pushing.
     */
    LinkList.prototype.pushFront = function (element) {
        this._insertNode(element, this._header);
        return this._length;
    };
    /**
     * @description Removes the first element.
     * @returns The element you popped.
     */
    LinkList.prototype.popFront = function () {
        if (this._length === 0)
            return;
        var value = this._head._value;
        this._eraseNode(this._head);
        return value;
    };
    LinkList.prototype.setElementByPos = function (pos, element) {
        if (pos < 0 || pos > this._length - 1) {
            throw new RangeError();
        }
        var curNode = this._head;
        while (pos--) {
            curNode = curNode._next;
        }
        curNode._value = element;
    };
    LinkList.prototype.insert = function (pos, element, num) {
        if (num === void 0) { num = 1; }
        if (pos < 0 || pos > this._length) {
            throw new RangeError();
        }
        if (num <= 0)
            return this._length;
        if (pos === 0) {
            while (num--)
                this.pushFront(element);
        }
        else if (pos === this._length) {
            while (num--)
                this.pushBack(element);
        }
        else {
            var curNode = this._head;
            for (var i = 1; i < pos; ++i) {
                curNode = curNode._next;
            }
            var next = curNode._next;
            this._length += num;
            while (num--) {
                curNode._next = {
                    _value: element,
                    _pre: curNode
                };
                curNode._next._pre = curNode;
                curNode = curNode._next;
            }
            curNode._next = next;
            next._pre = curNode;
        }
        return this._length;
    };
    LinkList.prototype.find = function (element) {
        var curNode = this._head;
        while (curNode !== this._header) {
            if (curNode._value === element) {
                return new LinkListIterator(curNode, this._header, this);
            }
            curNode = curNode._next;
        }
        return this.end();
    };
    LinkList.prototype.reverse = function () {
        if (this._length <= 1)
            return;
        var pHead = this._head;
        var pTail = this._tail;
        var cnt = 0;
        while ((cnt << 1) < this._length) {
            var tmp = pHead._value;
            pHead._value = pTail._value;
            pTail._value = tmp;
            pHead = pHead._next;
            pTail = pTail._pre;
            cnt += 1;
        }
    };
    LinkList.prototype.unique = function () {
        if (this._length <= 1) {
            return this._length;
        }
        var curNode = this._head;
        while (curNode !== this._header) {
            var tmpNode = curNode;
            while (tmpNode._next !== this._header &&
                tmpNode._value === tmpNode._next._value) {
                tmpNode = tmpNode._next;
                this._length -= 1;
            }
            curNode._next = tmpNode._next;
            curNode._next._pre = curNode;
            curNode = curNode._next;
        }
        return this._length;
    };
    LinkList.prototype.sort = function (cmp) {
        if (this._length <= 1)
            return;
        var arr = [];
        this.forEach(function (el) {
            arr.push(el);
        });
        arr.sort(cmp);
        var curNode = this._head;
        arr.forEach(function (element) {
            curNode._value = element;
            curNode = curNode._next;
        });
    };
    /**
     * @description Merges two sorted lists.
     * @param list - The other list you want to merge (must be sorted).
     * @returns The size of list after merging.
     * @example
     * const linkA = new LinkList([1, 3, 5]);
     * const linkB = new LinkList([2, 4, 6]);
     * linkA.merge(linkB);  // [1, 2, 3, 4, 5];
     */
    LinkList.prototype.merge = function (list) {
        var self = this;
        if (this._length === 0) {
            list.forEach(function (el) {
                self.pushBack(el);
            });
        }
        else {
            var curNode_1 = this._head;
            list.forEach(function (el) {
                while (curNode_1 !== self._header &&
                    curNode_1._value <= el) {
                    curNode_1 = curNode_1._next;
                }
                self._insertNode(el, curNode_1._pre);
            });
        }
        return this._length;
    };
    LinkList.prototype.forEach = function (callback) {
        var curNode = this._head;
        var index = 0;
        while (curNode !== this._header) {
            callback(curNode._value, index++, this);
            curNode = curNode._next;
        }
    };
    LinkList.prototype[Symbol.iterator] = function () {
        return function () {
            var curNode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._length === 0)
                            return [2 /*return*/];
                        curNode = this._head;
                        _a.label = 1;
                    case 1:
                        if (!(curNode !== this._header)) return [3 /*break*/, 3];
                        return [4 /*yield*/, curNode._value];
                    case 2:
                        _a.sent();
                        curNode = curNode._next;
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/];
                }
            });
        }.bind(this)();
    };
    return LinkList;
}(SequentialContainer));
export default LinkList;
