import { EventService } from "../eventService";
import { AgEvent, Events, RowEvent, RowSelectedEvent, SelectionChangedEvent } from "../events";
import { Column } from "./column";
import { Constants } from "../constants/constants";
import { IEventEmitter } from "../interfaces/iEventEmitter";
import { DetailGridInfo } from "../gridApi";
import { exists, missing, missingOrEmpty } from "../utils/generic";
import { getAllKeysInObjects } from "../utils/object";
import { IServerSideStore } from "../interfaces/IServerSideStore";
import { IClientSideRowModel } from "../interfaces/iClientSideRowModel";
import { IServerSideRowModel } from "../interfaces/iServerSideRowModel";
import { debounce } from "../utils/function";
import { Beans } from "../rendering/beans";

export interface SetSelectedParams {
    // true or false, whatever you want to set selection to
    newValue: boolean;
    // whether to remove other selections after this selection is done
    clearSelection?: boolean;
    // true when action is NOT on this node, ie user clicked a group and this is the child of a group
    suppressFinishActions?: boolean;
    // gets used when user shift-selects a range
    rangeSelect?: boolean;
    // used in group selection, if true, filtered out children will not be selected
    groupSelectsFiltered?: boolean;
}

export interface RowNodeEvent extends AgEvent {
    node: RowNode;
}

export interface DataChangedEvent extends RowNodeEvent {
    oldData: any;
    newData: any;
    update: boolean;
}

export interface CellChangedEvent extends RowNodeEvent {
    column: Column;
    newValue: any;
    oldValue: any;
}

export enum RowHighlightPosition { Above, Below }

export class RowNode implements IEventEmitter {

    public static ID_PREFIX_ROW_GROUP = 'row-group-';
    public static ID_PREFIX_TOP_PINNED = 't-';
    public static ID_PREFIX_BOTTOM_PINNED = 'b-';

    private static OBJECT_ID_SEQUENCE = 0;

    public static EVENT_ROW_SELECTED = 'rowSelected';
    public static EVENT_DATA_CHANGED = 'dataChanged';
    public static EVENT_CELL_CHANGED = 'cellChanged';
    public static EVENT_ALL_CHILDREN_COUNT_CHANGED = 'allChildrenCountChanged';
    public static EVENT_MASTER_CHANGED = 'masterChanged';
    public static EVENT_GROUP_CHANGED = 'groupChanged';
    public static EVENT_MOUSE_ENTER = 'mouseEnter';
    public static EVENT_MOUSE_LEAVE = 'mouseLeave';
    public static EVENT_HEIGHT_CHANGED = 'heightChanged';
    public static EVENT_TOP_CHANGED = 'topChanged';
    public static EVENT_DISPLAYED_CHANGED = 'displayedChanged';
    public static EVENT_FIRST_CHILD_CHANGED = 'firstChildChanged';
    public static EVENT_LAST_CHILD_CHANGED = 'lastChildChanged';
    public static EVENT_CHILD_INDEX_CHANGED = 'childIndexChanged';
    public static EVENT_ROW_INDEX_CHANGED = 'rowIndexChanged';
    public static EVENT_EXPANDED_CHANGED = 'expandedChanged';
    public static EVENT_HAS_CHILDREN_CHANGED = 'hasChildrenChanged';
    public static EVENT_SELECTABLE_CHANGED = 'selectableChanged';
    public static EVENT_UI_LEVEL_CHANGED = 'uiLevelChanged';
    public static EVENT_HIGHLIGHT_CHANGED = 'rowHighlightChanged';
    public static EVENT_DRAGGING_CHANGED = 'draggingChanged';

    /** Unique ID for the node. Either provided by the application, or generated by the grid if not. */
    public id: string | undefined;

    /** If using row grouping, contains the group values for this group. */
    public groupData: { [key: string]: any | null; } | null;

    /** If using row grouping and aggregation, contains the aggregation data. */
    public aggData: any;

    /** The data as provided by the application. */
    public data: any;

    /** The parent node to this node, or empty if top level */
    public parent: RowNode | null;

    /** How many levels this node is from the top when grouping. */
    public level: number;

    /** How many levels this node is from the top when grouping in the UI (only different to `parent` when `groupRemoveSingleChildren=true`)*/
    public uiLevel: number;

    /**
     * If doing in-memory (client-side) grouping, this is the index of the group column this cell is for.
     * This will always be the same as the level, unless we are collapsing groups, i.e. `groupRemoveSingleChildren=true`.
     */
    public rowGroupIndex: number | null;

    /** `true` if this node is a group node (ie has children) */
    public group: boolean | undefined;

    /** `true` if this row is getting dragged */
    public dragging: boolean;

    /** `true` if this row is a master row, part of master / detail (ie row can be expanded to show detail) */
    public master: boolean;

    /** `true` if this row is a detail row, part of master / detail (ie child row of an expanded master row)*/
    public detail: boolean;

    /** If this row is a master row that was expanded, this points to the associated detail row. */
    public detailNode: RowNode;

    /** If master detail, this contains details about the detail grid */
    public detailGridInfo: DetailGridInfo | null;

    /** `true` if this node is a group and the group is the bottom level in the tree. */
    public leafGroup: boolean;

    /** `true` if this is the first child in this group. Changes when data is sorted. */
    public firstChild: boolean;

    /** `true` if this is the last child in this group. Changes when data is sorted. */
    public lastChild: boolean;

    /** Index of this row with respect to its parent when grouping. Changes when data is sorted. */
    public childIndex: number;

    /** The current row index. If the row is filtered out or in a collapsed group, this value will be `null`. */
    public rowIndex: number | null = null;

    /** Either 'top' or 'bottom' if row pinned, otherwise `undefined` or `null`. */
    public rowPinned: string;

    /** If using quick filter, stores a string representation of the row for searching against. */
    public quickFilterAggregateText: string | null;

    /** `true` if row is a footer. Footers have `group = true` and `footer = true`. */
    public footer: boolean;

    /** The field we are grouping on eg 'country'. */
    public field: string | null;

    /** The row group column used for this group, e.g. the Country column instance. */
    public rowGroupColumn: Column | null;

    /** The key for the group eg Ireland, UK, USA */
    public key: string | null = null;

    /** Used by server-side row model. `true` if this row node is a stub. A stub is a placeholder row with loading icon while waiting from row to be loaded. */
    public stub: boolean;

    /** Used by server side row model, true if this row node failed a load */
    public failedLoad: boolean;

    /** All lowest level nodes beneath this node, no groups. */
    public allLeafChildren: RowNode[];

    /** Children of this group. If multi levels of grouping, shows only immediate children. */
    public childrenAfterGroup: RowNode[] | null;

    /** Filtered children of this group. */
    public childrenAfterFilter: RowNode[] | null;

    /** Aggregated and re-filtered children of this group. */
    public childrenAfterAggFilter: RowNode[] | null;

    /** Sorted children of this group. */
    public childrenAfterSort: RowNode[] | null;

    /** Number of children and grand children. */
    public allChildrenCount: number | null;

    /** Children mapped by the pivot columns. */
    public childrenMapped: { [key: string]: any; } | null = {};

    /** Server Side Row Model Only - the children are in an infinite cache. */
    public childStore: IServerSideStore | null;

    /** `true` if group is expanded, otherwise `false`. */
    public expanded: boolean;

    /** If using footers, reference to the footer node for this group. */
    public sibling: RowNode;

    /** The height, in pixels, of this row */
    public rowHeight: number | null | undefined;

    /** Dynamic row heights are done on demand, only when row is visible. However for row virtualisation
     * we need a row height to do the 'what rows are in viewport' maths. So we assign a row height to each
     * row based on defaults and rowHeightEstimated=true, then when the row is needed for drawing we do
     * the row height calculation and set rowHeightEstimated=false.*/
    public rowHeightEstimated: boolean;

    /**
     * This will be `true` if it has a rowIndex assigned, otherwise `false`.
     */
    public displayed: boolean = false;

    /** The row top position in pixels. */
    public rowTop: number | null = null;

    /** The top pixel for this row last time, makes sense if data set was ordered or filtered,
     * it is used so new rows can animate in from their old position. */
    public oldRowTop: number | null = null;

    /** `true` if this node is a daemon. This means row is not part of the model. Can happen when then
     * the row is selected and then the user sets a different ID onto the node. The nodes is then
     * representing a different entity, so the selection controller, if the node is selected, takes
     * a copy where daemon=true. */
    public daemon: boolean;

    /** `true` by default - can be overridden via gridOptions.isRowSelectable(rowNode) */
    public selectable = true;

    /** Used by the value service, stores values for a particular change detection turn. */
    public __cacheData: { [colId: string]: any; };
    public __cacheVersion: number;

    /** Used by sorting service - to give deterministic sort to groups. Previously we
     * just id for this, however id is a string and had slower sorting compared to numbers. */
    public __objectId: number = RowNode.OBJECT_ID_SEQUENCE++;

    /** We cache the result of hasChildren() so that we can be aware of when it has changed, and hence
     * fire the event. Really we should just have hasChildren as an attribute and do away with hasChildren()
     * method, however that would be a breaking change. */
    private __hasChildren: boolean;

    /** When one or more Columns are using autoHeight, this keeps track of height of each autoHeight Cell,
     * indexed by the Column ID. */
    private __autoHeights?: { [id: string]: number | undefined } = {};

    /** `true` when nodes with the same id are being removed and added as part of the same batch transaction */
    public alreadyRendered = false;

    public highlighted: RowHighlightPosition | null = null;

    private selected: boolean | undefined = false;
    private eventService: EventService | null;

    private beans: Beans;

    private checkAutoHeightsDebounced: () => void;

    private onRowHeightChangedDebounced = debounce(this.onRowHeightChanged.bind(this), 100);

    constructor(beans: Beans) {
        this.beans = beans;
    }

    /** Replaces the data on the `rowNode`. When complete, the grid will refresh the the entire rendered row if it is showing. */
    public setData(data: any): void {
        this.setDataCommon(data, false);
    }

    // similar to setRowData, however it is expected that the data is the same data item. this
    // is intended to be used with Redux type stores, where the whole data can be changed. we are
    // guaranteed that the data is the same entity (so grid doesn't need to worry about the id of the
    // underlying data changing, hence doesn't need to worry about selection). the grid, upon receiving
    // dataChanged event, will refresh the cells rather than rip them all out (so user can show transitions).
    public updateData(data: any): void {
        this.setDataCommon(data, true);
    }

    private setDataCommon(data: any, update: boolean): void {
        const oldData = this.data;

        this.data = data;
        this.beans.valueCache.onDataChanged();
        this.updateDataOnDetailNode();
        this.checkRowSelectable();

        const event: DataChangedEvent = this.createDataChangedEvent(data, oldData, update);

        this.dispatchLocalEvent(event);
    }

    // when we are doing master / detail, the detail node is lazy created, but then kept around.
    // so if we show / hide the detail, the same detail rowNode is used. so we need to keep the data
    // in sync, otherwise expand/collapse of the detail would still show the old values.
    private updateDataOnDetailNode(): void {
        if (this.detailNode) {
            this.detailNode.data = this.data;
        }
    }

    private createDataChangedEvent(newData: any, oldData: any, update: boolean): DataChangedEvent {
        return {
            type: RowNode.EVENT_DATA_CHANGED,
            node: this,
            oldData: oldData,
            newData: newData,
            update: update
        };
    }

    private createLocalRowEvent(type: string): RowNodeEvent {
        return {
            type: type,
            node: this
        };
    }

    public getRowIndexString(): string {
        if (this.rowPinned === Constants.PINNED_TOP) {
            return 't-' + this.rowIndex;
        }

        if (this.rowPinned === Constants.PINNED_BOTTOM) {
            return 'b-' + this.rowIndex;
        }

        return this.rowIndex!.toString();
    }

    private createDaemonNode(): RowNode {
        const oldNode = new RowNode(this.beans);

        // just copy the id and data, this is enough for the node to be used
        // in the selection controller (the selection controller is the only
        // place where daemon nodes can live).
        oldNode.id = this.id;
        oldNode.data = this.data;
        oldNode.daemon = true;
        oldNode.selected = this.selected;
        oldNode.level = this.level;

        return oldNode;
    }

    public setDataAndId(data: any, id: string | undefined): void {
        const oldNode = exists(this.id) ? this.createDaemonNode() : null;
        const oldData = this.data;

        this.data = data;
        this.updateDataOnDetailNode();
        this.setId(id);
        this.beans.selectionService.syncInRowNode(this, oldNode);
        this.checkRowSelectable();

        const event: DataChangedEvent = this.createDataChangedEvent(data, oldData, false);

        this.dispatchLocalEvent(event);
    }

    private checkRowSelectable() {
        const isRowSelectableFunc = this.beans.gridOptionsWrapper.getIsRowSelectableFunc();
        this.setRowSelectable(isRowSelectableFunc ? isRowSelectableFunc!(this) : true);
    }

    public setRowSelectable(newVal: boolean) {
        if (this.selectable !== newVal) {
            this.selectable = newVal;
            if (this.eventService) {
                this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_SELECTABLE_CHANGED));
            }
        }
    }

    public setId(id?: string): void {
        // see if user is providing the id's
        const getRowIdFunc = this.beans.gridOptionsWrapper.getRowIdFunc();

        if (getRowIdFunc) {
            // if user is providing the id's, then we set the id only after the data has been set.
            // this is important for virtual pagination and viewport, where empty rows exist.
            if (this.data) {
                // we pass 'true' as we skip this level when generating keys,
                // as we don't always have the key for this level (eg when updating
                // data via transaction on SSRM, we are getting key to look up the
                // RowNode, don't have the RowNode yet, thus no way to get the current key)
                const parentKeys = this.getGroupKeys(true);
                this.id = getRowIdFunc({
                    data: this.data,
                    parentKeys: parentKeys.length > 0 ? parentKeys : undefined,
                    level: this.level
                });
                // make sure id provided doesn't start with 'row-group-' as this is reserved. also check that
                // it has 'startsWith' in case the user provided a number.
                if (this.id !== null && typeof this.id === 'string' && this.id.startsWith(RowNode.ID_PREFIX_ROW_GROUP)) {
                    console.error(`AG Grid: Row IDs cannot start with ${RowNode.ID_PREFIX_ROW_GROUP}, this is a reserved prefix for AG Grid's row grouping feature.`);
                }
                // force id to be a string
                if (this.id !== null && typeof this.id !== 'string') {
                    this.id = '' + this.id;
                }
            } else {
                // this can happen if user has set blank into the rowNode after the row previously
                // having data. this happens in virtual page row model, when data is delete and
                // the page is refreshed.
                this.id = undefined;
            }
        } else {
            this.id = id;
        }
    }

    public getGroupKeys(excludeSelf = false): string[] {
        const keys: string[] = [];

        let pointer: RowNode | null = this;
        if (excludeSelf) {
            pointer = pointer.parent;
        }
        while (pointer && pointer.level >= 0) {
            keys.push(pointer.key!);
            pointer = pointer.parent;
        }
        keys.reverse();

        return keys;
    }

    public isPixelInRange(pixel: number): boolean {
        if (!exists(this.rowTop) || !exists(this.rowHeight)) { return false; }
        return pixel >= this.rowTop && pixel < (this.rowTop + this.rowHeight);
    }

    public setFirstChild(firstChild: boolean): void {
        if (this.firstChild === firstChild) { return; }

        this.firstChild = firstChild;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_FIRST_CHILD_CHANGED));
        }
    }

    public setLastChild(lastChild: boolean): void {
        if (this.lastChild === lastChild) { return; }

        this.lastChild = lastChild;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_LAST_CHILD_CHANGED));
        }
    }

    public setChildIndex(childIndex: number): void {
        if (this.childIndex === childIndex) { return; }

        this.childIndex = childIndex;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_CHILD_INDEX_CHANGED));
        }
    }

    public setRowTop(rowTop: number | null): void {
        this.oldRowTop = this.rowTop;

        if (this.rowTop === rowTop) { return; }

        this.rowTop = rowTop;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_TOP_CHANGED));
        }

        this.setDisplayed(rowTop !== null);
    }

    public clearRowTopAndRowIndex(): void {
        this.oldRowTop = null;
        this.setRowTop(null);
        this.setRowIndex(null);
    }

    private setDisplayed(displayed: boolean): void {
        if (this.displayed === displayed) { return; }

        this.displayed = displayed;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_DISPLAYED_CHANGED));
        }
    }

    public setDragging(dragging: boolean): void {
        if (this.dragging === dragging) { return; }

        this.dragging = dragging;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_DRAGGING_CHANGED));
        }
    }

    public setHighlighted(highlighted: RowHighlightPosition | null): void {
        if (highlighted === this.highlighted) { return; }

        this.highlighted = highlighted;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_HIGHLIGHT_CHANGED));
        }
    }

    public setAllChildrenCount(allChildrenCount: number | null): void {
        if (this.allChildrenCount === allChildrenCount) { return; }

        this.allChildrenCount = allChildrenCount;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_ALL_CHILDREN_COUNT_CHANGED));
        }
    }

    public setMaster(master: boolean): void {
        if (this.master === master) { return; }

        // if changing AWAY from master, then unexpand, otherwise
        // next time it's shown it is expanded again
        if (this.master && !master) {
            this.expanded = false;
        }

        this.master = master;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_MASTER_CHANGED));
        }
    }

    public setGroup(group: boolean): void {
        if (this.group === group) { return; }

        // if we used to be a group, and no longer, then close the node
        if (this.group && !group) {
            this.expanded = false;
        }

        this.group = group;
        this.updateHasChildren();

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_GROUP_CHANGED));
        }
    }

    /**
     * Sets the row height.
     * Call if you want to change the height initially assigned to the row.
     * After calling, you must call `api.onRowHeightChanged()` so the grid knows it needs to work out the placement of the rows. */
    public setRowHeight(rowHeight: number | undefined | null, estimated = false): void {
        this.rowHeight = rowHeight;
        this.rowHeightEstimated = estimated;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_HEIGHT_CHANGED));
        }
    }

    public setRowAutoHeight(cellHeight: number | undefined, column: Column): void {
        if (!this.__autoHeights) {
            this.__autoHeights = {};
        }
        const autoHeights = this.__autoHeights!;

        autoHeights[column.getId()] = cellHeight;

        if (cellHeight != null) {
            if (this.checkAutoHeightsDebounced == null) {
                this.checkAutoHeightsDebounced = debounce(this.checkAutoHeights.bind(this), 1);
            }
            this.checkAutoHeightsDebounced();
        }
    }

    public checkAutoHeights(): void {
        let notAllPresent = false;
        let nonePresent = true;
        let newRowHeight = 0;

        const autoHeights = this.__autoHeights!;
        if (autoHeights == null) { return; }

        const displayedAutoHeightCols = this.beans.columnModel.getAllDisplayedAutoHeightCols();
        displayedAutoHeightCols.forEach(col => {
            const cellHeight = autoHeights[col.getId()];
            if (cellHeight == null) {
                notAllPresent = true;
                return;
            }
            nonePresent = false;
            if (cellHeight > newRowHeight) {
                newRowHeight = cellHeight;
            }
        });

        if (notAllPresent) { return; }

        // we take min of 10, so we don't adjust for empty rows. if <10, we put to default.
        // this prevents the row starting very small when waiting for async components,
        // which would then mean the grid squashes in far to many rows (as small heights
        // means more rows fit in) which looks crap. so best ignore small values and assume
        // we are still waiting for values to render.
        if (nonePresent || newRowHeight < 10) {
            newRowHeight = this.beans.gridOptionsWrapper.getRowHeightForNode(this).height;
        }

        if (newRowHeight == this.rowHeight) { return; }

        this.setRowHeight(newRowHeight);

        this.onRowHeightChangedDebounced();
    }

    /** This method is debounced. It is used for row auto-height. If we don't debounce, 
     * then the Row Models will end up recalculating each row position
     * for each row height change and result in the Row Renderer laying out rows.
     * This is particularly bad if using print layout, and showing eg 1,000 rows,
     * each row will change it's height, causing Row Model to update 1,000 times.
     */
     private onRowHeightChanged(): void {
        const rowModel = this.beans.rowModel as (IClientSideRowModel | IServerSideRowModel);
        if (rowModel.onRowHeightChanged) {
            rowModel.onRowHeightChanged();
        }
    }

    public setRowIndex(rowIndex: number | null): void {
        if (this.rowIndex === rowIndex) { return; }

        this.rowIndex = rowIndex;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_ROW_INDEX_CHANGED));
        }
    }

    public setUiLevel(uiLevel: number): void {
        if (this.uiLevel === uiLevel) { return; }

        this.uiLevel = uiLevel;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_UI_LEVEL_CHANGED));
        }
    }

    public setExpanded(expanded: boolean, e?: MouseEvent | KeyboardEvent): void {
        if (this.expanded === expanded) { return; }

        this.expanded = expanded;

        if (this.eventService) {
            this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_EXPANDED_CHANGED));
        }

        const event = Object.assign({}, this.createGlobalRowEvent(Events.EVENT_ROW_GROUP_OPENED), {
            expanded,
            event: e || null
        });

        this.beans.rowNodeEventThrottle.dispatchExpanded(event);

        // when using footers we need to refresh the group row, as the aggregation
        // values jump between group and footer
        if (this.beans.gridOptionsWrapper.isGroupIncludeFooter()) {
            this.beans.rowRenderer.refreshCells({ rowNodes: [this] });
        }
    }

    private createGlobalRowEvent(type: string): RowEvent {
        return {
            type: type,
            node: this,
            data: this.data,
            rowIndex: this.rowIndex,
            rowPinned: this.rowPinned,
            context: this.beans.gridOptionsWrapper.getContext(),
            api: this.beans.gridOptionsWrapper.getApi()!,
            columnApi: this.beans.gridOptionsWrapper.getColumnApi()!
        };
    }

    private dispatchLocalEvent(event: AgEvent): void {
        if (this.eventService) {
            this.eventService.dispatchEvent(event);
        }
    }

    /**
     * Replaces the value on the `rowNode` for the specified column. When complete,
     * the grid will refresh the rendered cell on the required row only.
     *
     * @param colKey The column where the value should be updated
     * @param newValue The new value
     * @param eventSource The source of the event
     * @returns `True` if the value was changed, otherwise `False`.
     */
    public setDataValue(colKey: string | Column, newValue: any, eventSource?: string): boolean {
        // When it is done via the editors, no 'cell changed' event gets fired, as it's assumed that
        // the cell knows about the change given it's in charge of the editing.
        // this method is for the client to call, so the cell listens for the change
        // event, and also flashes the cell when the change occurs.
        const column = this.beans.columnModel.getPrimaryColumn(colKey)!;
        const oldValue = this.beans.valueService.getValue(column, this);

        const valueChanged = this.beans.valueService.setValue(this, column, newValue, eventSource);
        this.dispatchCellChangedEvent(column, newValue, oldValue);

        return valueChanged;
    }

    public setGroupValue(colKey: string | Column, newValue: any): void {
        const column = this.beans.columnModel.getGridColumn(colKey)!;

        if (missing(this.groupData)) { this.groupData = {}; }

        const columnId = column.getColId();
        const oldValue = this.groupData[columnId];

        if (oldValue === newValue) { return; }

        this.groupData[columnId] = newValue;
        this.dispatchCellChangedEvent(column, newValue, oldValue);
    }

    // sets the data for an aggregation
    public setAggData(newAggData: any): void {
        // find out all keys that could potentially change
        const colIds = getAllKeysInObjects([this.aggData, newAggData]);
        const oldAggData = this.aggData;

        this.aggData = newAggData;

        // if no event service, nobody has registered for events, so no need fire event
        if (this.eventService) {
            colIds.forEach(colId => {
                const column = this.beans.columnModel.getGridColumn(colId)!;
                const value = this.aggData ? this.aggData[colId] : undefined;
                const oldValue = oldAggData ? oldAggData[colId] : undefined;
                this.dispatchCellChangedEvent(column, value, oldValue);
            });
        }
    }

    public updateHasChildren(): void {
        // we need to return true when this.group=true, as this is used by server side row model
        // (as children are lazy loaded and stored in a cache anyway). otherwise we return true
        // if children exist.
        const newValue = (this.group && !this.footer) || (this.childrenAfterGroup && this.childrenAfterGroup.length > 0);

        if (newValue !== this.__hasChildren) {
            this.__hasChildren = !!newValue;
            if (this.eventService) {
                this.eventService.dispatchEvent(this.createLocalRowEvent(RowNode.EVENT_HAS_CHILDREN_CHANGED));
            }
        }
    }

    public hasChildren(): boolean {
        if (this.__hasChildren == null) {
            this.updateHasChildren();
        }
        return this.__hasChildren;
    }

    public isEmptyRowGroupNode(): boolean | undefined {
        return this.group && missingOrEmpty(this.childrenAfterGroup);
    }

    private dispatchCellChangedEvent(column: Column, newValue: any, oldValue: any): void {
        const cellChangedEvent: CellChangedEvent = {
            type: RowNode.EVENT_CELL_CHANGED,
            node: this,
            column: column,
            newValue: newValue,
            oldValue: oldValue
        };
        this.dispatchLocalEvent(cellChangedEvent);
    }

    /**
     * The first time `quickFilter` runs, the grid creates a one-off string representation of the row.
     * This string is then used for the quick filter instead of hitting each column separately.
     * When you edit, using grid editing, this string gets cleared down.
     * However if you edit without using grid editing, you will need to clear this string down for the row to be updated with the new values.
     * Otherwise new values will not work with the `quickFilter`. */
    public resetQuickFilterAggregateText(): void {
        this.quickFilterAggregateText = null;
    }

    public isExpandable(): boolean {
        return (this.hasChildren() && !this.footer) || this.master ? true : false;
    }

    /** Returns:
     * - `true` if node is selected,
     * - `false` if the node isn't selected
     * - `undefined` if it's partially selected (group where not all children are selected). */
    public isSelected(): boolean | undefined {
        // for footers, we just return what our sibling selected state is, as cannot select a footer
        if (this.footer) { return this.sibling.isSelected(); }

        return this.selected;
    }

    /** Perform a depth-first search of this node and its children. */
    public depthFirstSearch(callback: (rowNode: RowNode) => void): void {
        if (this.childrenAfterGroup) {
            this.childrenAfterGroup.forEach(child => child.depthFirstSearch(callback));
        }
        callback(this);
    }

    // + rowController.updateGroupsInSelection()
    // + selectionController.calculatedSelectedForAllGroupNodes()
    public calculateSelectedFromChildren(): void {
        let atLeastOneSelected = false;
        let atLeastOneDeSelected = false;
        let atLeastOneMixed = false;
        let newSelectedValue: boolean | undefined;

        if (!this.childrenAfterGroup?.length) {
            return;
        }

        for (let i = 0; i < this.childrenAfterGroup.length; i++) {
            const child = this.childrenAfterGroup[i];

            // skip non-selectable nodes to prevent inconsistent selection values
            if (!child.selectable) { continue; }

            const childState = child.isSelected();

            switch (childState) {
                case true:
                    atLeastOneSelected = true;
                    break;
                case false:
                    atLeastOneDeSelected = true;
                    break;
                default:
                    atLeastOneMixed = true;
                    break;
            }
        }

        if (atLeastOneMixed) {
            newSelectedValue = undefined;
        } else if (atLeastOneSelected && !atLeastOneDeSelected) {
            newSelectedValue = true;
        } else if (!atLeastOneSelected && atLeastOneDeSelected) {
            newSelectedValue = false;
        } else {
            newSelectedValue = undefined;
        }

        this.selectThisNode(newSelectedValue);
    }

    public setSelectedInitialValue(selected: boolean): void {
        this.selected = selected;
    }

    /**
     * Select (or deselect) the node.
     * @param newValue -`true` for selection, `false` for deselection.
     * @param clearSelection - If selecting, then passing `true` will select the node exclusively (i.e. NOT do multi select). If doing deselection, `clearSelection` has no impact.
     * @param suppressFinishActions - Pass `true` to prevent the `selectionChanged` from being fired. Note that the `rowSelected` event will still be fired.
     */
    public setSelected(newValue: boolean, clearSelection: boolean = false, suppressFinishActions: boolean = false) {
        this.setSelectedParams({
            newValue,
            clearSelection,
            suppressFinishActions,
            rangeSelect: false
        });
    }

    public isRowPinned(): boolean {
        return this.rowPinned === Constants.PINNED_TOP || this.rowPinned === Constants.PINNED_BOTTOM;
    }

    // to make calling code more readable, this is the same method as setSelected except it takes names parameters
    public setSelectedParams(params: SetSelectedParams): number {
        const groupSelectsChildren = this.beans.gridOptionsWrapper.isGroupSelectsChildren();
        const newValue = params.newValue === true;
        const clearSelection = params.clearSelection === true;
        const suppressFinishActions = params.suppressFinishActions === true;
        const rangeSelect = params.rangeSelect === true;
        // groupSelectsFiltered only makes sense when group selects children
        const groupSelectsFiltered = groupSelectsChildren && (params.groupSelectsFiltered === true);

        if (this.id === undefined) {
            console.warn('AG Grid: cannot select node until id for node is known');
            return 0;
        }

        if (this.rowPinned) {
            console.warn('AG Grid: cannot select pinned rows');
            return 0;
        }

        // if we are a footer, we don't do selection, just pass the info
        // to the sibling (the parent of the group)
        if (this.footer) {
            return this.sibling.setSelectedParams(params);
        }

        if (rangeSelect && this.beans.selectionService.getLastSelectedNode()) {
            const newRowClicked = this.beans.selectionService.getLastSelectedNode() !== this;
            const allowMultiSelect = this.beans.gridOptionsWrapper.isRowSelectionMulti();
            if (newRowClicked && allowMultiSelect) {
                const nodesChanged = this.doRowRangeSelection(params.newValue);
                this.beans.selectionService.setLastSelectedNode(this);
                return nodesChanged;
            }
        }

        let updatedCount = 0;

        // when groupSelectsFiltered, then this node may end up intermediate despite
        // trying to set it to true / false. this group will be calculated further on
        // down when we call calculatedSelectedForAllGroupNodes(). we need to skip it
        // here, otherwise the updatedCount would include it.
        const skipThisNode = groupSelectsFiltered && this.group;

        if (!skipThisNode) {
            const thisNodeWasSelected = this.selectThisNode(newValue);
            if (thisNodeWasSelected) {
                updatedCount++;
            }
        }

        if (groupSelectsChildren && this.childrenAfterGroup?.length) {
            updatedCount += this.selectChildNodes(newValue, groupSelectsFiltered);
        }

        // clear other nodes if not doing multi select
        if (!suppressFinishActions) {
            const clearOtherNodes = newValue && (clearSelection || !this.beans.gridOptionsWrapper.isRowSelectionMulti());
            if (clearOtherNodes) {
                updatedCount += this.beans.selectionService.clearOtherNodes(this);
            }

            // only if we selected something, then update groups and fire events
            if (updatedCount > 0) {
                this.beans.selectionService.updateGroupsFromChildrenSelections();

                // this is the very end of the 'action node', so we are finished all the updates,
                // include any parent / child changes that this method caused
                const event: SelectionChangedEvent = {
                    type: Events.EVENT_SELECTION_CHANGED,
                    api: this.beans.gridApi,
                    columnApi: this.beans.columnApi
                };
                this.beans.eventService.dispatchEvent(event);
            }

            // so if user next does shift-select, we know where to start the selection from
            if (newValue) {
                this.beans.selectionService.setLastSelectedNode(this);
            }
        }

        return updatedCount;
    }

    // selects all rows between this node and the last selected node (or the top if this is the first selection).
    // not to be mixed up with 'cell range selection' where you drag the mouse, this is row range selection, by
    // holding down 'shift'.
    private doRowRangeSelection(value: boolean = true): number {
        const groupsSelectChildren = this.beans.gridOptionsWrapper.isGroupSelectsChildren();
        const lastSelectedNode = this.beans.selectionService.getLastSelectedNode();
        const nodesToSelect = this.beans.rowModel.getNodesInRangeForSelection(this, lastSelectedNode);

        let updatedCount = 0;

        nodesToSelect.forEach(rowNode => {
            if (rowNode.group && groupsSelectChildren || (value === false && this === rowNode)) { return; }

            const nodeWasSelected = rowNode.selectThisNode(value);
            if (nodeWasSelected) {
                updatedCount++;
            }
        });

        this.beans.selectionService.updateGroupsFromChildrenSelections();

        const event: SelectionChangedEvent = {
            type: Events.EVENT_SELECTION_CHANGED,
            api: this.beans.gridApi,
            columnApi: this.beans.columnApi
        };

        this.beans.eventService.dispatchEvent(event);

        return updatedCount;
    }

    public isParentOfNode(potentialParent: RowNode): boolean {
        let parentNode = this.parent;

        while (parentNode) {
            if (parentNode === potentialParent) {
                return true;
            }
            parentNode = parentNode.parent;
        }

        return false;
    }

    public selectThisNode(newValue?: boolean): boolean {

        // we only check selectable when newValue=true (ie selecting) to allow unselecting values,
        // as selectable is dynamic, need a way to unselect rows when selectable becomes false.
        const selectionNotAllowed = !this.selectable && newValue;
        const selectionNotChanged = this.selected === newValue;

        if (selectionNotAllowed || selectionNotChanged) { return false; }

        this.selected = newValue;

        if (this.eventService) {
            this.dispatchLocalEvent(this.createLocalRowEvent(RowNode.EVENT_ROW_SELECTED));
        }

        const event: RowSelectedEvent = this.createGlobalRowEvent(Events.EVENT_ROW_SELECTED);

        this.beans.eventService.dispatchEvent(event);

        return true;
    }

    private selectChildNodes(newValue: boolean, groupSelectsFiltered: boolean): number {
        const children = groupSelectsFiltered ? this.childrenAfterAggFilter : this.childrenAfterGroup;

        if (missing(children)) { return 0; }

        let updatedCount = 0;

        for (let i = 0; i < children.length; i++) {
            updatedCount += children[i].setSelectedParams({
                newValue: newValue,
                clearSelection: false,
                suppressFinishActions: true,
                groupSelectsFiltered
            });
        }

        return updatedCount;
    }

    /** Add an event listener. */
    public addEventListener(eventType: string, listener: Function): void {
        if (!this.eventService) {
            this.eventService = new EventService();
        }
        this.eventService.addEventListener(eventType, listener);
    }

    /** Remove event listener. */
    public removeEventListener(eventType: string, listener: Function): void {
        if (!this.eventService) { return; }

        this.eventService.removeEventListener(eventType, listener);
        if (this.eventService.noRegisteredListenersExist()) {
            this.eventService = null;
        }
    }

    public onMouseEnter(): void {
        this.dispatchLocalEvent(this.createLocalRowEvent(RowNode.EVENT_MOUSE_ENTER));
    }

    public onMouseLeave(): void {
        this.dispatchLocalEvent(this.createLocalRowEvent(RowNode.EVENT_MOUSE_LEAVE));
    }

    public getFirstChildOfFirstChild(rowGroupColumn: Column | null): RowNode | null {
        let currentRowNode: RowNode = this;
        let isCandidate = true;
        let foundFirstChildPath = false;
        let nodeToSwapIn: RowNode | null = null;

        // if we are hiding groups, then if we are the first child, of the first child,
        // all the way up to the column we are interested in, then we show the group cell.
        while (isCandidate && !foundFirstChildPath) {
            const parentRowNode = currentRowNode.parent!;
            const firstChild = exists(parentRowNode) && currentRowNode.firstChild;

            if (firstChild) {
                if (parentRowNode.rowGroupColumn === rowGroupColumn) {
                    foundFirstChildPath = true;
                    nodeToSwapIn = parentRowNode;
                }
            } else {
                isCandidate = false;
            }

            currentRowNode = parentRowNode;
        }

        return foundFirstChildPath ? nodeToSwapIn : null;
    }

    public isFullWidthCell(): boolean {
        const isFullWidthCellFunc = this.beans.gridOptionsWrapper.getIsFullWidthCellFunc();
        return isFullWidthCellFunc ? isFullWidthCellFunc({ rowNode: this }) : false;
    }

    /**
     * Returns the route of the row node. If the Row Node is a group, it returns the route to that Row Node.
     * If the Row Node is not a group, it returns `undefined`.
     */
    public getRoute(): string[] | undefined {
        if (this.key == null) { return; }

        const res: string[] = [];

        let pointer: RowNode = this;

        while (pointer.key != null) {
            res.push(pointer.key);
            pointer = pointer.parent!;
        }

        return res.reverse();
    }
}
