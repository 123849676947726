var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ContainerIterator } from "../../ContainerBase";
import { throwIteratorAccessError } from "../../../utils/throwError";
var RandomIterator = /** @class */ (function (_super) {
    __extends(RandomIterator, _super);
    /**
     * @internal
     */
    function RandomIterator(index, iteratorType) {
        var _this = _super.call(this, iteratorType) || this;
        _this._node = index;
        if (_this.iteratorType === 0 /* IteratorType.NORMAL */) {
            _this.pre = function () {
                if (this._node === 0) {
                    throwIteratorAccessError();
                }
                this._node -= 1;
                return this;
            };
            _this.next = function () {
                if (this._node === this.container.size()) {
                    throwIteratorAccessError();
                }
                this._node += 1;
                return this;
            };
        }
        else {
            _this.pre = function () {
                if (this._node === this.container.size() - 1) {
                    throwIteratorAccessError();
                }
                this._node += 1;
                return this;
            };
            _this.next = function () {
                if (this._node === -1) {
                    throwIteratorAccessError();
                }
                this._node -= 1;
                return this;
            };
        }
        return _this;
    }
    Object.defineProperty(RandomIterator.prototype, "pointer", {
        get: function () {
            return this.container.getElementByPos(this._node);
        },
        set: function (newValue) {
            this.container.setElementByPos(this._node, newValue);
        },
        enumerable: false,
        configurable: true
    });
    return RandomIterator;
}(ContainerIterator));
export { RandomIterator };
