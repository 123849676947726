var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Base } from "../ContainerBase";
var Queue = /** @class */ (function (_super) {
    __extends(Queue, _super);
    function Queue(container) {
        if (container === void 0) { container = []; }
        var _this = _super.call(this) || this;
        /**
         * @internal
         */
        _this._first = 0;
        /**
         * @internal
         */
        _this._queue = [];
        var self = _this;
        container.forEach(function (el) {
            self.push(el);
        });
        return _this;
    }
    Queue.prototype.clear = function () {
        this._queue = [];
        this._length = this._first = 0;
    };
    /**
     * @description Inserts element to queue's end.
     * @param element - The element you want to push to the front.
     * @returns The container length after pushing.
     */
    Queue.prototype.push = function (element) {
        var capacity = this._queue.length;
        if ((this._first / capacity) > 0.5 /* QUEUE_CONSTANT.ALLOCATE_SIGMA */ &&
            (this._first + this._length) >= capacity &&
            capacity > 4096 /* QUEUE_CONSTANT.MIN_ALLOCATE_SIZE */) {
            var length_1 = this._length;
            for (var i = 0; i < length_1; ++i) {
                this._queue[i] = this._queue[this._first + i];
            }
            this._first = 0;
            this._queue[this._length] = element;
        }
        else
            this._queue[this._first + this._length] = element;
        return ++this._length;
    };
    /**
     * @description Removes the first element.
     * @returns The element you popped.
     */
    Queue.prototype.pop = function () {
        if (this._length === 0)
            return;
        var el = this._queue[this._first++];
        this._length -= 1;
        return el;
    };
    /**
     * @description Access the first element.
     * @returns The first element.
     */
    Queue.prototype.front = function () {
        if (this._length === 0)
            return;
        return this._queue[this._first];
    };
    return Queue;
}(Base));
export default Queue;
